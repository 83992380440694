

.overflow-x-auto::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.overflow-x-auto{
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 


    display: flex;
    overflow: auto;
    width: 100%;
    border: 5px solid #ffffff;
    border-radius: 10px;
}

.overflow-x-auto * {
    margin-right: 2%; /* Only for demo */
}

.icon-text{
  font-size: 8px
}