*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 1px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 1px;
    border-radius: 10px;
}

::-webkit-scrollbar {
    height: 10px;
    /* height of horizontal scrollbar ← You're missing this */
    width: 10px;
    /* width of vertical scrollbar */
    border: 1px solid red;
    border-radius: 10px;
}

/* body {
  text-align: center;
  background: #00C8FF;
}
p {
  font-size: 1.6em;
  font-family: "Lato", sans-serif;
  background-color: #fff;
  padding: 1em;
  color: #002240;
  margin-top: 0;
} */
/* .button */
.button {
    display: inline-block;
    position: relative;
    /* border: 2px solid red; */
    overflow: hidden;
    text-decoration: none;
    outline: none;
    /* color: red; */
    background: transparent;
    font-family: 'raleway', sans-serif;
}

.button span {
    -webkit-transition: 0.6s;
    -moz-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
    -webkit-transition-delay: 0.2s;
    -moz-transition-delay: 0.2s;
    -o-transition-delay: 0.2s;
    transition-delay: 0.2s;
}

.button:before,
.button:after {
    content: '';
    position: absolute;
    top: 0.67em;
    left: 0;
    width: 100%;
    text-align: center;
    opacity: 0;
    -webkit-transition: 0.4s, opacity 0.6s;
    -moz-transition: 0.4s, opacity 0.6s;
    -o-transition: 0.4s, opacity 0.6s;
    transition: 0.4s, opacity 0.6s;
}

/* :before */
.button:before {
    color: #fff;
    content: attr(data-hover);
    -webkit-transform: translate(-150%, 0);
    -moz-transform: translate(-150%, 0);
    -ms-transform: translate(-150%, 0);
    -o-transform: translate(-150%, 0);
    transform: translate(-150%, 0);
}

/* :after */
.button:after {
    content: attr(data-active);
    -webkit-transform: translate(150%, 0);
    -moz-transform: translate(150%, 0);
    -ms-transform: translate(150%, 0);
    -o-transform: translate(150%, 0);
    transform: translate(150%, 0);
}

/* Span on :hover and :active */
.button:hover span,
.button:active span {
    color: #fff;
    opacity: 0;
    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    -ms-transform: scale(0.3);
    -o-transform: scale(0.3);
    transform: scale(0.3);
}

/*  
    We show :before pseudo-element on :hover 
    and :after pseudo-element on :active 
*/
.button:hover:before,
.button:active:after {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transition-delay: 0.4s;
    -moz-transition-delay: 0.4s;
    -o-transition-delay: 0.4s;
    transition-delay: 0.4s;
}

/* 
  We hide :before pseudo-element on :active
*/
.button:active:before {
    -webkit-transform: translate(-150%, 0);
    -moz-transform: translate(-150%, 0);
    -ms-transform: translate(-150%, 0);
    -o-transform: translate(-150%, 0);
    transform: translate(-150%, 0);
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
}

body {
    margin: 0;
    font-family: 'DM Sans', sans-serif !important;
}

html {
    scroll-behavior: smooth;
}

p {
    margin-top: 0;
    margin-bottom: 0rem;
    font-family: DM Sans;
    font-weight: 400;
    font-style: normal;
    line-height: 30.09px;
    letter-spacing: 4%;
}

/* img{
  inline-size: fit-content
} */
#myBtn {
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    border: none;
    outline: none;
    background-color: #07a58e;
    color: white;
    cursor: pointer;
    padding: 2px;
    border-radius: 50%;
    font-size: 12px;
    box-shadow: 0 1px 6px 0 rgb(32 33 36 / 20%);
}

.showcase {
    text-align: left;
    z-index: 20000 !important;
    margin: 0px auto;
    min-height: auto !important;
}

.faq-hero {
    min-height: 0vh !important;
}

.nav-custom {
    z-index: 2;
}

.faq-hero input {
    background: #ffffff;
    border: 2px solid #07a58e;
    border-radius: 61px;
    border-radius: 46px;
    color: #07a58e;
    padding: 10px 40px !important;
    outline: none;
}

.hero {
    min-height: 100vh !important;
}

.showcase button {
    background: #07a58e;
    border-radius: 46px;
    color: #fff;
    padding: 10px 40px !important;
}

.why-huzz button {
    background: #07a58e;
    border-radius: 46px;
    color: #fff;
    padding: 10px 40px !important;
}

nav .btn {
    background: #07a58e;
    border-radius: 46px;
    color: #fff;
    padding: 10px 40px !important;
    border: 1px solid #07a58e;
    color: #fff;
}

nav a {
    color: #07a58e;
    text-decoration: none;
    background-color: transparent;
}

#root>div>div>section:nth-child(2)>div>div:nth-child(2) {
    min-height: 60vh;
}

nav {
    color: #07a58e;
}

.phone-image-1 {
    position: absolute;
    top: -30%;
    left: -10%;
    z-index: 1;
}

.phone-image-2 {
    position: absolute;
    top: -6%;
    left: 44%;
    z-index: 1;
}

.showcase h1 {
    font-family: DM Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    line-height: 63px;
    letter-spacing: 0.04em;
    color: #000000;
}

.why-huzz {
    background: rgba(7, 165, 142, 0.05);
    min-height: auto;
}

.why-huzz h1 {
    color: #000000;
    text-align: left;
}

.why-huzz .coloured {
    background: #07a58e;
    box-shadow: 2px 0px 59px 1px rgba(0, 0, 0, 0.11);
    border-radius: 52px;
    color: #fff;
    padding: 40px 60px;
    margin-top: 100px !important;
    margin-bottom: 100px !important;
}

.why-huzz .monochrome {
    background: #fff;
    box-shadow: 2px 0px 59px 1px rgba(0, 0, 0, 0.11);
    border-radius: 52px;
    color: #000;
    padding: 40px 60px;
    margin-top: 100px !important;
    margin-bottom: 100px !important;
}

.mt-20 {
    margin-top: 10%;
}

.mt-25 {
    margin-top: 25%;
}

footer {
    background: #07a58e;
    padding: 5% 0px;
    color: #fff;
}

footer li {
    list-style: none;
    text-align: left;
    justify-content: left;
    margin: 10px 0px;
}

footer ul {
    margin-bottom: 30px;
    margin-left: 0px !important;
    padding-inline-start: 0px;
}

footer .footer-logo {
    width: 80%;
    justify-content: center;
    text-align: center;
    margin: auto;
}

.social-icons {
    display: flex !important;
}

.social-icons li {
    margin-right: 20px;
}

.typewrite {
    color: #07a58e !important;
}

/* .showcase img{
  width: 100%
}
.hero{
  width:auto!important
} */
.newsletter input {
    background: #ffffff;
    border: 2px solid #07a58e;
    border-radius: 61px;
    border-radius: 46px;
    color: #07a58e;
    padding: 10px 40px !important;
    outline: none;
}

.d-none {
    display: none;
}

.footer-flex {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
}

.row-1 {
    width: 50%;
}

.row-2 {
    width: 50%;
}

.nav-link {
    color: #07a58e;
}

.faq-hero {
    min-height: 10vh !important;
}

#root>div>section.showcase.container.faq-hero>div>section>center>div>div>div.download-icons>input {
    height: 58px;
    min-width: 75%;
    border: 2px solid #07a58e;
    box-sizing: border-box;
    border-radius: 17px;
}

#root>div>section.showcase.container.faq-hero>div>section>center>div>div>div.download-icons>button {
    position: absolute;
    left: 72%;
    top: 71%;
    min-height: 20px;
    background: #07a58e;
    border-radius: 17px;
}

#root>div>section>div>section>div>div.col-md-3.mt-20.left p {
    font-size: 12px;
}

#root>div>section>div>section>div>div.col-md-9.mt-5.right>h5 {
    color: #07a58e;
    font-weight: 400px;
}

/* #root > div > section > div > section > div > div.col-md-3.mt-20.left{
  position: fixed;
  width: 100px;
} */
footer>div>div>div>div>ul>a {
    color: #fff;
    cursor: pointer;
}

.main-navbar {
    width: 100%;
    z-index: 100;
    background-color: #fff;
    background: #fff;
    /* box-shadow: 0 1px 6px 0 rgb(32 33 36 / 8%); */
    /* height: 75px; */
    /* overflow: hidden; */
}

a {
    cursor: pointer;
}

a:hover {
    color: #fff !important;
    text-decoration: none;
}

#root>div>section>div>section>div>div.col-md-3.mt-20.left a {
    color: #07a58e;
    font-weight: 400px;
}

#root>div>section>div>section>div>div.col-md-3.mt-20.left a:hover {
    color: #07a58e !important;
}

/* 
.why-huzz img{
  inline-size: fit-content
}
.showcase img{
  inline-size: fit-content
} */
.why-huzz-icons {
    position: absolute;
    left: 10%;
    top: -25%;
}

#root>div>section:nth-child(11)>div>div.col-md-6 {
    margin: auto;
}

#root>div>section:nth-child(11)>div>div.col-md-6.center-allign-text {
    text-align: center !important;
}

#root>div>section:nth-child(9)>div>div.col-md-6.center-allign-text {
    text-align: center !important;
    margin: auto;
}

/* #root>div>section:nth-child(11)>div>div.col-md-6.center-allign-text img {
  width: 40%!important;
}
#root>div>div:nth-child(14)>footer>div>div>div>div:nth-child(3)>a>p>b {
  color: #fff!important
} */
footer>div>div>div>div:nth-child(3)>a>p>b,
footer>div>div>div>div:nth-child(4)>a>li {
    color: #fff !important;
}

@media only screen and (max-width: 600px) {

    /* body {
    background-color: lightblue;
  } */
    /* img{
    inline-size: -webkit-fill-available
  } */
    .center-allign-text {
        text-align: center;
    }

    .why-huzz-icons {
        position: absolute;
        left: 40%;
        top: -25%;
        width: 15%;
    }

    .main-navbar {
        /* position: fixed; */
        box-shadow: 0 1px 6px 0 rgb(32 33 36 / 8%);
        height: 77px;
        /* overflow: hidden; */
    }

    #root>div>div:nth-child(1)>div>nav>a>img {
        width: 55%;
    }

    .navbar-nav {
        background-color: #fff;
        z-index: 100;
    }

    /* img{
  inline-size: normal
} */
    .phone-image-1 {
        width: 90%;
        position: absolute;
        top: -250%;
        left: -13%;
    }

    .phone-image-2 {
        width: 50%;
        position: absolute;
        top: 6%;
        left: 40%;
    }

    .rmt-0 {
        margin-top: 0px !important;
    }

    .rml-0 {
        margin-left: 0px !important;
    }

    .playstore {
        width: 40%;
    }

    .applestore {
        width: 40%;
    }

    .showcase h1 {
        font-family: DM Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 35px;
        line-height: 63px;
        letter-spacing: 0.04em;
        color: #000000;
        text-align: center;
    }

    p {
        margin-top: 0;
        margin-bottom: 0rem;
        font-family: DM Sans;
        font-weight: 400;
        font-style: normal;
        line-height: 30.09px;
        letter-spacing: 4%;
        text-align: center;
    }

    .why-huzz .monochrome {
        background: #fff;
        box-shadow: 2px 0px 59px 1px rgb(0 0 0 / 11%);
        border-radius: 52px;
        color: #07a58e;
        padding: 40px 60px;
        margin-top: 0px !important;
        margin-bottom: 100px !important;
    }

    .rd-none {
        display: none;
    }

    .d-none {
        display: block !important;
    }

    .download-icons {
        text-align: center;
    }

    .r-center {
        text-align: center !important;
    }

    .rd-none {
        display: none !important;
    }

    .why-huzz h1 {
        font-family: DM Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 35px;
        line-height: 63px;
        letter-spacing: 0.04em;
        color: #000000;
        text-align: center;
    }

    .why-huzz .coloured {
        background: #07a58e;
        box-shadow: 2px 0px 59px 1px rgb(0 0 0 / 11%);
        border-radius: 52px;
        color: #fff;
        padding: 20px 60px;
        margin-top: 0px !important;
        margin-bottom: 50px !important;
    }

    .why-huzz .monochrome {
        background: #fff;
        box-shadow: 2px 0px 59px 1px rgb(0 0 0 / 11%);
        border-radius: 52px;
        color: #000;
        padding: 20px 60px;
        margin-top: 0px !important;
        margin-bottom: 50px !important;
    }

    #root>div>div>div>div>nav>a>img {
        width: 80%;
    }

    .navbar-brand {
        display: inline-block;
        padding-top: 0.3125rem;
        padding-bottom: 0.3125rem;
        margin-right: -2rem;
        font-size: 1.25rem;
        line-height: inherit;
        white-space: nowrap;
    }

    nav .btn {
        background: #07a58e;
        border-radius: 46px;
        color: #fff;
        padding: 8px 20px !important;
        border: 1px solid #07a58e;
        color: #fff;
        /* font-size: 14px; */
    }

    /* #root > div > div > footer > div > div{
  display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: none!important;
    margin-right: -15px;
    margin-left: -15px
} */
    .footer-flex {
        display: flex;
    }

    .row-1 {
        width: 50%;
    }

    .row-2 {
        width: 50%;
    }

    #root>div>div>footer>div>div>div>div:nth-child(1)>center>img {
        width: 50% !important;
        text-align: left !important;
        justify-content: center !important;
    }

    footer p {
        margin-top: 0;
        margin-bottom: 0rem;
        font-family: DM Sans;
        font-weight: 400;
        font-style: normal;
        line-height: 30.09px;
        letter-spacing: 4%;
        text-align: left;
    }

    #root>div>div>section:nth-child(9)>div>div>div:nth-child(2)>button {
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: transparent;
        --blue: #007bff;
        --indigo: #6610f2;
        --purple: #6f42c1;
        --pink: #e83e8c;
        --red: #dc3545;
        --orange: #fd7e14;
        --yellow: #ffc107;
        --green: #28a745;
        --teal: #20c997;
        --cyan: #17a2b8;
        --white: #fff;
        --gray: #6c757d;
        --gray-dark: #343a40;
        --primary: #007bff;
        --secondary: #6c757d;
        --success: #28a745;
        --info: #17a2b8;
        --warning: #ffc107;
        --danger: #dc3545;
        --light: #f8f9fa;
        --dark: #343a40;
        --breakpoint-xs: 0;
        --breakpoint-sm: 576px;
        --breakpoint-md: 768px;
        --breakpoint-lg: 992px;
        --breakpoint-xl: 1200px;
        --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
            'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji',
            'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
            'Courier New', monospace;
        box-sizing: border-box;
        margin: 0;
        font-family: inherit;
        overflow: visible;
        text-transform: none;
        -webkit-appearance: button;
        display: inline-block;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        user-select: none;
        border: 1px solid transparent;
        font-size: 1rem;
        line-height: 1.5;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin-top: 1rem !important;
        background: #07a58e;
        border-radius: 46px;
        color: #fff;
        padding: 10px 40px !important;
        transition-property: opacity, transform;
        transition-duration: 0.4s;
        transition-timing-function: ease;
        cursor: pointer;
        opacity: 1;
        transform: translateZ(0);
        align-items: center !important;
    }

    #root>div>section.showcase.container.faq-hero>div>section>center>div>div>div.download-icons>input {
        height: 58px;
        min-width: 100%;
        border: 2px solid #07a58e;
        box-sizing: border-box;
        border-radius: 17px;
    }

    #root>div>section.showcase.container.faq-hero>div>section>center>div>div>div.download-icons>button {
        position: absolute;
        left: 49%;
        top: 77%;
        min-height: 20px;
        background: #07a58e;
        border-radius: 17px;
    }

    .privacy-policy p {
        margin-top: 0;
        margin-bottom: 0rem;
        font-family: DM Sans;
        font-weight: 400;
        font-style: normal;
        line-height: 30.09px;
        letter-spacing: 4%;
        text-align: left;
    }

    .privacy-policy h1 {
        text-align: left;
    }

    #root>div>div:nth-child(1)>div.main-navbar>nav>a>a>img {
        width: 60%;
    }

    #root>div>section:nth-child(11)>div>div.row.mt-5.mb-5>div {
        background-color: #fff;
    }
}

#root>div>section:nth-child(9)>div>div.row.mt-5.mb-5.m-2.center-allign-text>div {
    background-color: #fff;
    margin-right: auto;
    border-radius: 20px;
}

#root>div>section:nth-child(9)>div>div.row.mt-5.mb-5.m-2.center-allign-text>div img {
    width: 20%;
    margin-top: 10px;
}

.Typewriter {
    display: inline !important;
    /* color: #07A58E */
}

.download-icons-flex {
    display: flex;
}

.flex {
    display: flex;
}

.flex-why-huzz {
    justify-content: center;
}

/* Overlay Styling */
html {
    scroll-behavior: smooth;
}

.main-title {
    color: #2d2d2d;
    text-align: center;
    text-transform: capitalize;
    padding: 0.7em 0;
}

.overlay-container {
    padding: 1em 0;
    float: left;
    width: 50%;
}

@media screen and (max-width: 640px) {
    .overlay-container {
        display: block;
        width: 100%;
    }
}

@media screen and (min-width: 900px) {
    .overlay-container {
        width: 33.33333%;
    }
}

.overlay-container .title {
    color: #1a1a1a;
    text-align: center;
    margin-bottom: 10px;
}

.content {
    position: relative;
    width: 90%;
    max-width: 400px;
    margin: auto;
    overflow: hidden;
}

.content .content-overlay {
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    height: 99%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    border-radius: 8px;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.content:hover .content-overlay {
    opacity: 1;
}

.content-image {
    width: 100%;
}

.content-details {
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.content:hover .content-details {
    top: 50%;
    left: 50%;
    opacity: 1;
}

.content-details h3 {
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.15em;
    margin-bottom: 0.5em;
    text-transform: uppercase;
}

.content-details p {
    color: #fff;
    font-size: 0.8em;
}

.fadeIn-bottom {
    top: 80%;
}

.fadeIn-top {
    top: 20%;
}

.fadeIn-left {
    left: 20%;
}

.fadeIn-right {
    left: 80%;
}

.z-100 {
    z-index: 100;
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none !important;
}

.w-10 {
    width: 23px;
}

.why-huzz-icons-courosel {
    position: absolute;
    left: 35%;
    top: -15%;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.why-huzz .coloured-courosel {
    background: #fff;
    box-shadow: 2px 0px 20px 1px rgb(0 0 0 / 5%);
    border-radius: 10px 10px 0px 0px;
    color: #fff;
    padding: 60px 40px 15px;
    margin-top: 0px !important;
    margin-bottom: 00px !important;
}

/* .why-huzz .coloured-courosel {
  box-shadow: 2px 0px 29px 1px rgb(0 0 0 / 11%);
} */
.why-huzz .monochrome-courosel {
    background: rgba(7, 146, 126, 0.06);
    box-shadow: none;
    border-radius: 0px 0px 10px 10px;
    color: #000;
    padding: 20px 40px 20px;
    margin-top: 00px !important;
    margin-bottom: 00px !important;
    min-height: 250px;
    max-height: 200px;
}

.pt-10 {
    padding-top: 40px !important;
}

.why-huzz-courosel {
    background: #fff;
    min-height: auto;
}

#root>div>section:nth-child(10)>div>div.row.mt-5.mb-5.m-2.center-allign-text>div {
    background-color: #fff;
    border-radius: 15px;
    padding: 20px;
    margin-right: 90px;
}

.text-black {
    color: #111 !important;
}

.customer-icon {
    width: 30% !important;
    border-radius: 100%;
}

.social-proof .why-huzz-icons {
    width: 60px !important;
    border-radius: 100%;
}

.social-proof .coloured {
    margin-top: 3rem;
}

.social-proof .icon-1 {
    position: absolute;
    left: 95px;
    top: -100px;
}

.social-proof .icon-2 {
    position: absolute;
    left: 145px;
    top: -100px;
}

.social-proof .icon-3 {
    position: absolute;
    left: 195px;
    top: -100px;
}

.social-proof .icon-4 {
    position: absolute;
    left: 245px;
    top: -100px;
}

.social-proof .icon-5 {
    position: absolute;
    left: 295px;
    top: -100px;
}

.social-proof {
    top: 120px;
    position: relative;
    background-color: #fff;
    padding: 20px;
    border: 1px solid #fff;
    border-radius: 20px;
    box-shadow: 2px 0px 59px 1px rgba(0, 0, 0, 0.11);
    text-align: center;
}

@media screen and (max-width: 640px) {
    .hero {
        min-height: 115vh !important;
    }

    .social-proof .coloured {
        margin-top: 2rem;
    }

    .social-proof {
        top: 50vh;
        position: relative;
        background-color: #fff;
        padding: 20px;
        border: 1px solid #fff;
        border-radius: 20px;
        box-shadow: 2px 0px 59px 1px rgba(0, 0, 0, 0.11);
        text-align: center;
    }

    .social-proof h2 {
        font-size: 20px !important;
    }

    .social-proof .icon-1 {
        position: absolute;
        left: 50px;
        top: -80px;
    }

    .social-proof .icon-2 {
        position: absolute;
        left: 100px;
        top: -80px;
    }

    .social-proof .icon-3 {
        position: absolute;
        left: 150px;
        top: -80px;
    }

    .social-proof .icon-4 {
        position: absolute;
        left: 200px;
        top: -80px;
    }

    .social-proof .icon-5 {
        position: absolute;
        left: 250px;
        top: -80px;
    }
}

.placeholder-avatar {
    border: 1px solid #fff;
    background-color: #07a58e;
    padding: 30px;
    border-radius: 100%;
    text-align: center;
    padding: 24px 30px;
}

.placeholder-avatar h1 {
    text-align: center;
    color: #fff !important;
    font-weight: 900;
}

.testimonial-avatar {
    border: 1px solid #fff;
    background-color: #07a58e;
    border-radius: 100%;
    text-align: center;
    padding: 19px 23px;
}

.testimonial-avatar h1 {
    font-size: 30px;
    text-align: center;
    color: #fff !important;
    font-weight: 900;
    line-height: 20px;
    width: 20% !important;
}

p{
    font-size: 1rem
}

.center{
    display: flex;
    justify-content: center;
    allign-item: center;
    vertical=align: middle
}

.pagenation svg {
    vertical-align: none!important;
}


.cursor-pointes{
    cursor: pointer;
}


.image-overflow {
    overflow: hidden;
}

.max-h-100{
    max-height: 80px;
}
.max-h-140{
    max-height: 11em;
}
.nav-overflow{
    overflow: overlay;
}

.nav-overflow::-webkit-scrollbar {
    height: 0px;
    width: 0px;
    border: 1px solid red;
    border-radius: 10px;
}